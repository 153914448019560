/* You can add global styles to this file, and also import other style files */

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

.mat-mdc-snack-bar-container.app-notification-error {
  --mat-snack-bar-button-color: #842029;
  --mat-mdc-snack-bar-button-color: #842029;
  --mdc-snackbar-supporting-text-color: #842029;
  --mdc-snackbar-container-color: #f8d7da !important
}

.mat-mdc-snack-bar-container.app-notification-success {
  --mat-snack-bar-button-color: #0f5132;
  --mat-mdc-snack-bar-button-color: #0f5132;
  --mdc-snackbar-supporting-text-color: #0f5132;
  --mdc-snackbar-container-color: #d1e7dd !important;
}
.mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
  padding: 10px !important;
}
  .faa-sidenav-toolbar {
  margin: 10px !important;
}
.faa-sidenav-container {
  margin: 10px !important;
}
.faa-sidenav-content {
  position: relative !important;
  overflow-y: auto !important;
  flex-grow: 1 !important;
  width: 100% !important;
}



.faa-sidenav-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
/*  left: 0;*/
  right: 0;
  z-index: 50000;
  background: #ffffff;
  /* color: #495057;*/
  border: 0 none;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}


.mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
  color: rgb(0 0 0 / 99%) !important;
}
.mat-full-width {
  width: 100%;
}
:host ::ng-deep input.native-checkbox.ng-star-inserted {
  width: 16px !important;
}

input.native-checkbox.ng-star-inserted {
  width: 16px !important;
  padding-right: 10px !important;
}

.mat-mini-fab .mat-button-wrapper {
  line-height: 20px !important;
}

.p-sidebar-mask, .p-sidebar-mask.p-sidebar-mask-leave.p-component-overlay {
  background-color: transparent !important;
}

.cdk-overlay-container {
  z-index: 99999 !important;
}

.card {
  margin-top: 5px !important;
}

.btn-outline-primary {
  color: #1b6ec2 !important;
  border-color: #1b6ec2 !important;
}

  .btn-outline-primary:hover {
    color: white !important;
    background-color: #1b6ec2;
    border-color: #1b6ec2;
  }
/* agGrid */
.ag-theme-alpine {
  font-size: 11px;
}

  .ag-theme-alpine .ag-ltr .ag-cell {
    font-size: 11px;
  }
/* agGrid */
/* Provide sufficient contrast against white background */
/*.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0076C0  !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #89c4ea !important;
}*/
.primary-dk-color {
  background-color: #003E7E !important;
  background-color: #003E7E !important;
  color: white !important;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-home-link {
  padding: 25px 16px !important;
  font-size: 23px !important;
  font-weight: bold !important;
}

  .btn-home-link:hover {
    background-color: #003E7E !important;
    transform: scale(1.1);
  }

  .btn-home-link::after {
    transition: all 300ms !important;
  }

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/*Customize style*/
.full-width-form {
  min-width: 150px;
  max-width: 600px;
  width: 100%;
}

.full-width-field {
  width: 100%;
}

.app-icons-outlined {
  border-radius: 50% !important;
  border: solid;
  font-size: 18px;
  height: 20px;
  width: 20px;
}

.mat-icon > label {
  margin-right: 0.2em;
  margin-top: 0.2em;
}

.navbar-dark .navbar-toggler, .navbar-dark[_ngcontent-c1] .navbar-toggler[_ngcontent-c1] {
  color: rgba(255, 255, 255, 0.5);
  border-color: #0076C0 !important;
}

.navbar.navbar-expand-lg.navbar-dark.primary-color {
  background-color: #0076C0 !important;
  /*border-color: #0076C0 !important;*/
}

.navbar {
  width: 100%;
}

.asd-card {
  margin-top: 5px;
}

.asd-list-group-font-13 {
  font-size: 13px !important;
}

  .asd-list-group-font-13 > .list-group-item {
    font-size: 13px !important;
    padding: .55rem 0.75rem !important;
  }

.p-paginator.p-component {
  float: right !important;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.bg-success-light {
  background-color: #42a759bf;
}
.bg-primary-light {
  background-color: #a6d5fa;
}
.bg-danger-light {
  background-color: #ee5c5cd6;
}

